import {Link} from 'gatsby'
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import '../styles/main.scss'
import {Location} from '@reach/router'
import logo from '../images/logo.svg'
import phone from '../images/icons/phone-green.svg'
import Helmet from 'react-helmet'

class Header extends Component {

    state = {
        scrolled: false,
        showMenu: false
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = (event) => {

        if (window.scrollY >= 100) {
            this.setState({scrolled: true})
        } else {
            this.setState({scrolled: false})
        }
    }

    toggleMenu = () => {
        this.setState({
            showMenu: !this.state.showMenu
        })
    }
    render() {
        const navActive = this.state.showMenu
            ? `nav active`
            : `nav`;
        const menuActive = this.state.showMenu
            ? `menu visibleMenu`
            : `menu`;
        const burgerActive = this.state.showMenu
            ? `burger open`
            : `burger`;

        return (

            <nav className={navActive}>
                <Helmet
                    bodyAttributes={{
                    class: this.state.showMenu
                        ? `preload`
                        : ``
                }}/>
                <div className='nav__inner'>
                    <div className='nav__container'>
                        <div className='nav__logo'>
                            <Location>
                                {({location}) => {

                                    return (
                                        <Link
                                            to="/"
                                            className={(this.state.scrolled && location.pathname === '/') || location.pathname !== '/'
                                            ? `nav__logoLink show`
                                            : `nav__logoLink show`}>
                                            <img className='nav__logoImg' src={logo} alt="Świecki - Kancelaria Adwokacka"/>
                                        </Link>

                                    )
                                }}
                            </Location>
                        </div>
                        <div className='nav__phone'>
                            <a className='nav__phoneIcon' href="tel:+48 42 208 81 80"><img src={phone} alt=""/></a>
                        </div>

                        <button
                            aria-label="open menu"
                            className={burgerActive}
                            onClick={this.toggleMenu}>
                            <span className='burger__span'></span>
                            <span className='burger__span'></span>
                            <span className='burger__span'></span>
                            <span className='burger__span'></span>
                        </button>
                        <ul className={menuActive}>
                            <Location>
                                {({location}) => {

                                    return (
                                        <li
                                            className={location.pathname === '/grupa-prawna/'
                                            ? `menu__item active`
                                            : `menu__item`}>
                                            <Link to="/grupa-prawna/" className='menu__link' activeClassName='active'>
                                                Grupa prawna
                                            </Link>
                                        </li>
                                    )
                                }}
                            </Location>
                            <Location>
                                {({location}) => {

                                    return (
                                        <li
                                            className={location.pathname === '/zespol/'
                                            ? `menu__item active`
                                            : `menu__item`}>
                                            <Link to="/zespol/" className='menu__link' activeClassName='active'>
                                                Zespół
                                            </Link>
                                        </li>
                                    )
                                }}
                            </Location>
                            <Location>
                                {({location}) => {

                                    return (
                                        <li
                                            className={location.pathname === '/specjalizacje/'
                                            ? `menu__item active`
                                            : `menu__item`}>
                                            <Link to="/specjalizacje/" className='menu__link' activeClassName='active'>
                                                Specjalizacja
                                            </Link>
                                        </li>
                                    )
                                }}
                            </Location>
                            <Location>
                                {({location}) => {

                                    return (
                                        <li
                                            className={location.pathname === '/klienci/'
                                            ? `menu__item active`
                                            : `menu__item`}>
                                            <Link to="/klienci/" className='menu__link' activeClassName='active'>
                                                Zaufali nam
                                            </Link>
                                        </li>
                                    )
                                }}
                            </Location>
                            <Location>
                                {({location}) => {

                                    return (
                                        <li
                                            className={location.pathname === '/blog/'
                                            ? `menu__item active`
                                            : `menu__item`}>
                                            <Link to="/blog/" className='menu__link' activeClassName='active'>
                                                #InfoPrawne
                                            </Link>
                                        </li>
                                    )
                                }}
                            </Location>
                            <Location>
                                {({location}) => {

                                    return (
                                        <li
                                            className={location.pathname === '/media/'
                                            ? `menu__item active`
                                            : `menu__item`}>
                                            <Link to="/media/" className='menu__link' activeClassName='active'>
                                                Media
                                            </Link>
                                        </li>
                                    )
                                }}
                            </Location>
                            <Location>
                                {({location}) => {

                                    return (
                                        <li
                                            className={location.pathname === '/kontakt/'
                                            ? `menu__item active`
                                            : `menu__item`}>
                                            <Link to="/kontakt/" className='menu__link' activeClassName='active'>
                                                Kontakt
                                            </Link>
                                        </li>
                                    )
                                }}
                            </Location>
                            <Location>
                                {({location}) => {

                                    return (
                                        <li className='menu__item menu__item--green'>
                                            <a href="tel:+48 42 208 81 80" className='menu__link menu__link--green'>

                                                +48 42 208 81 80
                                            </a>
                                        </li>
                                    )
                                }}
                            </Location>

                        </ul>
                    </div>
                </div>
            </nav>
        )

    }
}

Header.propTypes = {
    siteTitle: PropTypes.string
}

Header.defaultProps = {
    siteTitle: ''
}

export default Header
